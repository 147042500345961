<template>
  <div class="ym-table-list"
       style="width: 100% ;">
    <!-- 按钮操作栏 -->
    <div class="control-bar border-style"
         v-if="this.$slots.topInfo">
      <div>
        <slot name="topInfo"></slot>
      </div>
    </div>
    <div class="control-bar border-style"
         v-if="this.$slots.topControl || operators.length">
      <div>
        <slot name="topControl"></slot>
      </div>
      <div class="top-control-bar"
           :style="{'justify-content': setCtrlAlign()}"
           v-if="operators.length">
        <template v-for="item in operators">
          <el-button :key="item.label"
                     :class="item.class"
                     :icon="!item.class && item.icon ? item.icon : ''"
                     :type="item.type"
                     size="small"
                     @click="handleRoutes(item.url)"
                     v-if=item.url>{{ item.label }}</el-button>
          <el-button :key="item.label"
                     :class="item.class"
                     :icon="!item.class && item.icon ? item.icon : ''"
                     :type="item.type"
                     size="small"
                     @click="item.handler(selection)"
                     v-else>{{ item.label }}</el-button>
        </template>
      </div>
    </div>
    <div class="border-style"
         v-loading="isLoading">
      <!-- 列表 -->

      <el-table :data="currentData"
                @sort-change="handleSortChange"
                @selection-change="handleSelectionChange"
                @row-click="handleRowClick"
                :row-key="getDataKey"
                :row-style="{height: '62px', color: '#4C576E'}"
                :header-row-style="{height: '82px', 'background-color': '#fff', 'font-size': '16px', color: '#222B45', padding: '36px 0 30px'}"
                ref="multipleTable"
                stripe
                border
                fit
                v-bind="$attrs"
                v-on="$listeners">
        <!-- 最左侧列-->
        <el-table-column v-if="selectCol"
                         type='selection'
                         :reserve-selection="true"
                         width="70"
                         align="center"
                         fixed></el-table-column>
        <el-table-column v-if=indexCol
                         type='index'
                         label="序号"
                         :width='selectCol?"60":"100"'
                         :align='selectCol?"center":"center"'
                         :index="indexMethod"></el-table-column>
        <!-- 遍历表头数据 -->
        <template v-for="col in columns">
          <!-- 扩展栏列表数据 -->
          <el-table-column v-bind="[col]"
                           :key="col.prop"
                           v-if="col.enableColSlot || col.type == 'expand'">
            <template slot-scope="scope">
              <slot :name="col.prop || col.type"
                    :row="scope.row"></slot>
            </template>
          </el-table-column>
          <!-- 标签类列表数据 -->
          <el-table-column v-bind="[col]"
                           :key="col.prop"
                           v-else-if="col.prop == 'tag'">
            <template slot-scope="scope">
              <template v-for="(t, i) in scope.row.tag">
                <el-tag v-if="t"
                        :key="i"
                        class="tag-col"
                        disable-transitions>{{t}}</el-tag>
              </template>
            </template>
          </el-table-column>
          <!-- 文本类列表数据 -->
          <el-table-column v-bind="[col]"
                           :key="col.prop"
                           v-else></el-table-column>
        </template>
        <!-- 右侧操作栏 -->
        <el-table-column v-if="showOperatorCol()"
                         label="操作"
                         :width="operatorWidth"
                         fixed="right">
          <template slot-scope="scope">
            <!-- 操作栏按钮不折叠时 -->
            <template v-if="wrapOperatorBtn === false">
              <el-button class="operator-btn ym-icon-liulan"
                         @click.stop="handleView(scope.row)"
                         v-if="enableView">查看</el-button>
              <el-button class="operator-btn ym-icon-bianji"
                         @click.stop="handleEdit(scope.row)"
                         v-if="enableEdit">编辑</el-button>
              <slot name="operatorCol"
                    :row="scope.row"
                    v-if="enableOperatorSlot"></slot>
              <el-button class="operator-btn operator-btn-delete"
                         icon="el-icon-delete"
                         @click.stop="handleDelete(scope.row, $event)"
                         v-if="enableDelete">删除</el-button>
            </template>
            <!-- 操作栏按钮折叠时(以后备用) -->
            <!-- <template v-else>
              <el-button class="operator-btn"
                         icon="el-icon-view"
                         @click.stop="handleView(scope.row)"
                         v-if=enableView>查看</el-button>
              <el-dropdown placement="bottom-start">
                <span class="el-dropdown-link"
                      @click="stopProgation">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-edit"
                                    @click.native="handleEdit(scope.row)"
                                    v-if=enableEdit>
                    <span>编辑</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided>
                    <slot name="operatorColWrap"
                          :row="scope.row"
                          @click.native="handleOperatorSlot(row)"></slot>
                  </el-dropdown-item>
                  <el-dropdown-item icon="el-icon-delete"
                                    @click.native="handleDelete(scope.row, $event)"
                                    v-if=enableDelete
                                    divided>
                    <span>删除</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template> -->
          </template>
        </el-table-column>

      </el-table>
      <!-- 底部导航栏 -->
      <el-row class="pagination"
              v-if="enablePagination">
        <div class="pagination-container"
             v-if="paginationReload">
          <div class="slot-part"
               ref="slotPart"
               v-if="enablePaginationSlot">
            <slot name='pagination'
                  :selection=selection></slot>
          </div>
          <div class="pagination-part"
               ref="paginationPart"
               v-if="enableElPagination">
            <div class="pages">
              <el-pagination :layout="currentPageLayout ? currentPageLayout : pageLayout"
                             :total="total"
                             :page-size="currentPageSize"
                             :page-sizes="pageSizes"
                             :current-page.sync="currentPage"
                             :pager-count="currentPagerCount"
                             @current-change="handlePageChange"
                             @size-change="handleSizeChange"
                             small
                             ref="elPagination"></el-pagination>
            </div>
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      currentData: [], // 当前页数据
      currentPage: 1, // 当前页码
      directPage: 1, // 需要跳转的页面，默认跟随pageNumber
      currentPageSize: 10,
      selection: [], // 已选的所有数据内容
      isLoading: false,
      currentPageLayout: '',
      originWidth: 0, // 分页栏底部插槽和分页部分不在同一行时的屏幕宽度
      paginationReload: true, // 用于刷新分页栏
      paginationFixedWidth: 0, // 用以保存触发分页栏自适应时的窗口宽度，用于恢复分页栏原本样式
      currentPagerCount: 7,
      fixFlag: false // 分页栏是否发生自适应样式调整
    }
  },
  props: {
    // 显示的数据
    data: {
      type: Array,
      default: () => []
    },
    // 表格列属性
    columns: {
      type: Array,
      default: () => []
    },
    // 表格上方按钮操作栏
    operators: {
      type: Array,
      default: () => []
    },
    // 唯一值，用于区分不同数据项
    dataKey: {
      type: String,
      default: 'id'
    },
    // 表格最左侧选择栏
    selectCol: {
      type: Boolean,
      default: false
    },
    // 最侧序号栏
    indexCol: {
      type: Boolean,
      default: false
    },
    // 右侧操作栏宽度
    operatorWidth: {
      type: String
    },
    // 显示右侧操作栏查看按钮
    enableView: {
      type: Boolean,
      default: false
    },
    // 显示右侧操作栏编辑按钮
    enableEdit: {
      type: Boolean,
      default: false
    },
    // 显示右侧操作栏删除按钮
    enableDelete: {
      type: Boolean,
      default: false
    },
    // 自定义右侧操作栏按钮
    enableOperatorSlot: {
      type: Boolean,
      default: false
    },
    // 是否开启操作栏按钮折叠
    wrapOperatorBtn: {
      type: Boolean,
      default: false
    },
    // 点击操作栏删除按钮时，弹出的提示框的标题
    confirmTitle: {
      type: String,
      default: '提示'
    },
    // 点击操作栏删除按钮时，弹出的提示框的内容
    confirmMessage: {
      type: String,
      default: '确认删除?'
    },
    // 弹出提示框确认按钮文本
    confirmButtonText: {
      type: String,
      default: '确定'
    },
    // 弹出提示框取消按钮文本
    cancelButtonText: {
      type: String,
      default: '取消'
    },
    // 处理点击每行时的操作
    // 'select'：点击选择或者反选该行
    // 'click': 点击返回当前行数据
    // 为其他则不作任何操作
    rowClick: {
      type: String
    },
    // 是否使用底部分页栏(包括插槽和el分页)
    enablePagination: {
      type: Boolean,
      default: true
    },
    // 是否使用底部分页栏左侧插槽
    enablePaginationSlot: {
      type: Boolean,
      default: false
    },
    // 是否使用分页栏右侧分页器
    enableElPagination: {
      type: Boolean,
      default: true
    },
    // 父组件传入的当前页码
    pageNumber: {
      type: Number,
      default: 1
    },
    // 单页数据量
    pageSize: {
      type: Number,
      default: 10
    },
    // 单页数据量配置项
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40, 50, 100]
    },
    // 数据总数
    total: {
      type: Number
    },
    // 分页栏显示模式
    pageLayout: {
      type: String,
      default: 'prev, pager, next'
    },
    // 页码按钮数量
    pagerCount: {
      type: Number,
      default: 7
    },
    // 头部按钮对齐类型
    ctrlAlign: {
      type: String,
      default: 'left'
    },
    // 加载动画
    loading: {
      type: Boolean,
      default: false
    },
    // // 分页栏页码部分对齐类型
    // pagAlign: {
    //   type: String,
    //   default: 'left'
    // },
    // 左侧索引栏显示模式
    indexMethod: {
      type: Function,
      default: (index) => {
        return index + 1
      }
    },
    clearSelectionFlag: {
      type: Boolean,
      defaut: false
    }
  },
  mounted() {
    this.currentData = this.data
    this.currentPage = this.pageNumber
    this.directPage = this.currentPage
    this.currentPageSize = this.pageSize
    this.currentPageLayout = this.pageLayout
    this.currentPagerCount = this.pagerCount
    if (this.enablePagination && this.enableElPagination) {
      this.setJumper()
      // window.setTimeout(this.paginationAutoFix(), 500)
      // window.addEventListener('resize', this.debounce(this.paginationAutoFix, 200))
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounce(this.paginationAutoFix, 200))
  },
  watch: {
    pageNumber() {
      this.currentPage = this.pageNumber
      this.clearSelection()
    },
    currentPage() {
      this.directPage = this.currentPage
    },
    pageSize() {
      this.currentPageSize = this.pageSize
    },
    loading() {
      this.isLoading = this.loading
    },
    data: {
      handler(newData, oldData) {
        this.currentData = this.data
      },
      deep: true
    },
    clearSelectionFlag (newValue, old) {
      if (newValue === true) {
        this.clearSelection()
      }
    }
  },
  methods: {
    // 实现页面跳转
    handleRoutes(url) {
      this.$router.push(url)
    },
    // selectCol为true时，存储所有选择项
    handleSelectionChange(selection) {
      this.selection = selection
      this.$emit('selectChange', this.selection)
    },
    // 清楚所有选择项
    clearSelection() {
      this.$refs.multipleTable.clearSelection()
    },
    // 是否显示操作栏
    showOperatorCol() {
      return this.enableView || this.enableEdit || this.enableDelete || this.enableOperatorSlot
    },
    // 点击每行返回当前行信息
    handleRowClick(row) {
      if (this.rowClick === 'select') {
        this.$refs.multipleTable.toggleRowSelection(row)
      } else if (this.rowClick === 'click') {
        this.$emit('handleRowClick', row)
      }
    },
    // 阻止事件穿透
    stopProgation() {
      event.stopPropagation()
    },
    // 处理操作栏查看按钮
    handleView(row) {
      this.$emit('handleView', row)
    },
    // 处理操作栏修改按钮
    handleEdit(row) {
      this.$emit('handleEdit', row)
    },
    // 处理操作栏删除按钮
    handleDelete(row) {
      this.$confirm(this.confirmMessage, this.confirmTitle, {
        confirmButtonText: this.confirmButtonText,
        cancelButtonText: this.cancelButtonText,
        type: 'warning'
      }).then(() => {
        this.$emit('handleDelete', row)
        // this.$message({ type: 'success', message: '删除成功!' })
      }).catch(() => { })
    },
    // 根据是前端排序还是后端
    handleSortChange({ column, prop, order }) {
      if (column.sortable === 'custom') {
        this.$emit('handleSortChange', { column, prop, order })
      }
    },
    // 分页保留选择项，row.id需为唯一值
    getDataKey(row) {
      return row[this.dataKey] // 仅作demo使用，需要讨论后制定
    },
    setCtrlAlign() {
      switch (this.ctrlAlign) {
        case 'left':
          return 'flex-start'
        case 'center':
          return 'center'
        case 'right':
          return 'flex-end'
        default:
          return 'flex-start'
      }
    },
    // 改变当前页数据量大小
    handleSizeChange(size) {
      this.$emit('handlePageJump', 1, size)
      this.currentPageSize = size
      this.currentPage = 1
    },
    // 点击页码跳转页面
    handlePageChange(page) {
      this.$emit('handlePageJump', page, this.currentPageSize)
    },
    // 输入页码跳转页面
    pageJump() {
      // this.pageNumber = Number(this.directPage)
      if (!isNaN(Number(this.directPage))) {
        this.$emit('handlePageJump', Number(this.directPage), this.currentPageSize)
        this.currentPage = Number(this.directPage)
      }
    },
    setJumper() {
      // 根据UI规范调整分页栏jumper显示
      if (this.currentPageLayout.includes('jumper')) {
        let jumper = document.getElementsByClassName('el-pagination__jump')[0]
        // 将“前往”改为“跳转”
        jumper.childNodes[0].nodeValue = '跳转'
        // 移出“页”字
        let remove = jumper.childNodes[2]
        jumper.removeChild(remove)
        // 新增跳转图标，样式及响应事件
        let jumpIcon = document.createElement('i')
        jumpIcon.className = 'ym-icon-fanye'
        // jumpIcon.addEventListener('click', this.pageJump)
        jumper.appendChild(jumpIcon)
      }
    },
    // 返回当前窗口宽度
    getCurrentWindowWidth() {
      return window.innerWidth
    },
    // 设置底部分页栏排布
    paginationAutoFix() {
      // 若this.paginationFixedWidth为0，即没有发生自适应样式调整
      if (!this.paginationFixedWidth) {
        let slotPart, elPaginationChildren
        if (this.enablePaginationSlot) {
          slotPart = this.$refs.slotPart
        }
        let paginationPart = this.$refs.paginationPart
        let elPagination = this.$refs.elPagination
        if (elPagination) {
          elPaginationChildren = elPagination.$children
        }
        // 获取el-pagination动态子元素
        let total, sizes, btnPrev, btnNext, pager, jump
        if (elPaginationChildren) {
          for (let i = 0; i < elPaginationChildren.length; i++) {
            if (elPaginationChildren[i].$el.className === 'el-pagination__total') {
              total = elPaginationChildren[i].$el
            } else if (elPaginationChildren[i].$el.className === 'el-pagination__sizes') {
              sizes = elPaginationChildren[i].$el
            } else if (elPaginationChildren[i].$el.className === 'btn-prev') {
              btnPrev = elPaginationChildren[i].$el
            } else if (elPaginationChildren[i].$el.className === 'btn-next') {
              btnNext = elPaginationChildren[i].$el
            } else if (elPaginationChildren[i].$el.className === 'el-pager') {
              pager = elPaginationChildren[i].$el
            } else if (elPaginationChildren[i].$el.className === 'el-pagination__jump') {
              jump = elPaginationChildren[i].$el
            }
          }
        }

        let paginationPartWidth = paginationPart ? paginationPart.clientWidth : 0
        // pagination所有显示组件的带margin宽度
        let paginationWidth = (total ? total.clientWidth + 10 : 0) + (sizes ? sizes.clientWidth + 10 : 0) + (btnPrev ? btnPrev.clientWidth : 0) + (btnNext ? btnNext.clientWidth : 0) + (pager ? pager.clientWidth : 0) + (jump ? jump.clientWidth + 80 : 0)
        // 若分页栏存在且发生换行，或者当前窗口宽度小于1024px启动自适应
        if ((paginationPartWidth && paginationWidth && (paginationWidth > paginationPartWidth) && this.getCurrentWindowWidth() <= 1366) || this.getCurrentWindowWidth() <= 1024) {
          // 记录发生自适应时的页面宽度，用以恢复原来的样式
          this.paginationFixedWidth = this.getCurrentWindowWidth()
          // this.currentPagerCount = 5
          if (slotPart) {
            slotPart.style.width = '100%'
            slotPart.className += ' clearfix'
          }
          if (paginationPart) {
            paginationPart.style.textAlign = 'center'
            paginationPart.style.float = 'none'
          }
          if (total) {
            total.style.width = '100%'
            total.style.marginTop = '1.0625%'
            total.style.marginBottom = '1.0625%'
          }
          if (sizes) {
            sizes.style.width = '100%'
          }
          if (btnPrev) {
            btnPrev.style.width = '10%'
          }
          if (btnNext) {
            btnNext.style.width = '10%'
          }
          if (pager) {
            pager.style.width = '80%'
          }
          if (jump) {
            jump.style.width = '100%'
            jump.style.marginLeft = '0'
          }
          this.fixFlag = true
        }
      } else if ((this.getCurrentWindowWidth() > this.paginationFixedWidth) && (this.getCurrentWindowWidth() > 1024)) {
        if (this.fixFlag) {
          // fixFlag为true即发生过自适应样式调整，执行分页栏刷新
          this.reloadPagination()
          this.fixFlag = false
          this.paginationFixedWidth = 0
        }
      }
    },
    // 恢复初始分页栏样式
    reloadPagination() {
      this.paginationReload = false
      this.$nextTick(function () {
        this.paginationReload = true
        this.currentPagerCount = this.pagerCount
        window.setTimeout(this.setJumper, 0)
      })
    },
    // 防抖
    debounce(fn, delay) {
      let timer = null
      return function () {
        let args = arguments
        if (timer) {
          clearTimeout(timer)
        }
        timer = window.setTimeout(() => {
          fn.apply(this, args)
        }, delay)
      }
    }
  }
}
</script>

<style lang="scss">
.ym-table-list {
  min-width: 440px;
  .border-style {
    border: $--header-border;
    border-radius: $--border-radius-base;
    background-color: $--header-bg-color;
    overflow: hidden;
  }
  .control-bar {
    background-color: $--header-bg-color;
    margin-bottom: 19px;
    .top-control-bar {
      display: flex;
      padding-right: 2%;
      .el-button {
        margin-left: 2%;
      }
      .el-button {
        height: 46px;
        font-size: 16px;
        margin-top: 1.063%;
        margin-bottom: 1.063%;
      }
    }
    .top-control-tag {
      border-bottom: $--header-border;
      padding-right: 2%;
      .el-tag {
        height: 41px;
        font-size: 14px;
        line-height: 41px;
        background-color: $--color-primary-light-9;
        border: none;
        border-radius: 6px;
        // color: #4c576e;
        color: $--color-text-primary;
        padding-left: 14px;
        padding-right: 14px;
        margin-top: 1.25%;
        margin-bottom: 1.25%;
        .el-icon-close {
          margin-left: 8px;
          // color: #4c576e;
          color: $--color-text-primary;
          &:hover {
            background-color: transparent;
          }
        }
      }
      .el-tag {
        margin-left: 2%;
      }
    }
    .top-control-dropdown {
      padding-right: 2%;
      .el-dropdown {
        height: 41px;
        line-height: 41px;
        border-radius: 6px;
        font-size: 14px;
        margin-top: 1.063%;
        overflow: hidden;
        .el-button {
          border: none;
          height: 41px;
          // color: #4c576e;
          color: $--color-text-primary;
          border: $--header-border;
          border-radius: 6px;
          &:hover,
          &:focus {
            color: $--color-primary;
            background-color: $--header-bg-color;
            border-color: $--color-primary;
          }
        }
      }
      .el-dropdown {
        margin-left: 2%;
      }
    }
  }
  .el-table {
    border: none;
    &:after {
      width: 0;
    }
    td {
      border-bottom: 0;
      border-right: 0;
      color: $--color-text-primary;
    }
    th {
      border-right: 0;
      background-color: $--header-bg-color;
    }
    .el-checkbox__inner {
      width: 24px;
      height: 24px;
      border: 1px solid $--color-text-placeholder;
      border-radius: $--border-radius-base;
      &::before {
        height: 4px;
        top: 9px;
      }
      &::after {
        width: 5px;
        height: 10px;
        left: 8px;
        top: 4px;
        border-width: 2px;
      }
    }
    tr {
      &.el-table__row--striped {
        td {
          background-color: $--background-color-light;
        }
      }
    }
    .tag-col {
      padding-left: 14px;
      padding-right: 14px;
      margin: 1% 0;
      background-color: $--color-primary-light-9;
      color: $--color-text-primary;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 2%;
    }
    .operator-btn {
      border: none;
      background-color: transparent;
      color: $--color-text-primary;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      padding: 0;
      margin-right: 13px;
      + .operator-btn {
        margin-left: 0;
      }
      &:hover {
        color: $--color-primary;
      }
    }
    .operator-btn-delete:hover {
      color: $--color-danger;
    }
  }

  .pagination {
    min-height: 68px;
    padding-left: 5%;
    padding-right: 5%;

    .pagination-container {
      width: 100%;
      .slot-part {
        float: left;
        margin-top: 0.2%;
        margin-bottom: 0.2%;
        margin-right: 10px;
        .pagination-btn {
          height: 32px;
          border: $--header-border;
          border-radius: 6px;
          padding-top: 9px;
          padding-bottom: 9px;
          margin-top: 15px;
          margin-bottom: 15px;
          color: $--color-text-primary;
        }
        .pagination-btn {
          margin-left: 1.6875%;
        }
      }
      .pagination-part {
        float: right;
        margin-top: 0.2%;
        margin-bottom: 0.2%;
        .pages {
          .el-pagination {
            padding: 0;
            font-weight: 400;
            color: $--color-text-primary;
            .el-pagination__total {
              float: left;
              margin-top: 17px;
              margin-bottom: 17px;
              color: $--color-text-primary;
              transform: translateY(3.5px);
            }
            .el-pagination__sizes {
              float: left;
              margin-top: 17px;
              margin-bottom: 17px;
              .el-input__inner {
                color: $--color-text-primary;
              }
            }
            .btn-prev,
            .btn-next {
              float: left;
              margin-top: 17px;
              margin-bottom: 17px;
              transform: translateY(2.5px);
            }
            .el-pager {
              float: left;
              margin-top: 17px;
              margin-bottom: 17px;
              transform: translateY(2.5px);
              li {
                font-size: 14px;
                padding: 0 16px;
                &.active {
                  color: $--color-primary;
                }
              }
            }
            .el-pagination__jump {
              float: left;
              font-size: 14px;
              margin: 17px 0 17px 80px;
              color: $--color-text-primary;
              transform: translateY(-3.5px);
              .el-input {
                box-sizing: content-box;
                width: 60px;
                margin-right: 9px;
                margin-left: 9px;
                .el-input__inner {
                  height: 32px;
                  padding: 10px 8px;
                  border-radius: 4px;
                }
              }
              i {
                width: 15px;
                height: 12px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .ym-icon- {
    &fanye {
      font-size: 12px;
      color: $--color-text-primary;
      &:hover {
        color: $--color-primary;
      }
    }
  }
  [class*="ym-icon-"] {
    &::before {
      margin-right: 5px;
    }
  }
  // .el-select-dropdown {
  //   padding: 0;
  //   margin: 0;
  //   .el-select-dropdown__item {
  //     padding: 0 20px;
  //     font-size: 14px;
  //     height: 50px;
  //     line-height: 50px;
  //     color: $--color-text-primary;
  //     opacity: 0.85;
  //     &:not(.is-disabled):hover,
  //     &:focus {
  //       color: $--color-text-primary;
  //       background-color: $--background-color-base;
  //       opacity: 1;
  //     }
  //   }
  //   .popper__arrow {
  //     border: 0;
  //     &::after {
  //       content: none;
  //     }
  //   }
  // }
}
</style>
